<template>
    <div class="oneHundred" v-show="ac.id">
      <div class="head">
        <p class="title">{{ac.activityName}}</p>
        <p class="time">{{ac.gmtStartEffect}} ~ {{ac.gmtEndEffect}}</p>
      </div>
      <div class="thisDay">
        <div class="this-data">
          <p class="this-t">本期获取积分</p>
          <p class="this-num">{{totalIntegral}}</p>
        </div>
        <div class="this-data">
          <p class="this-t">本期答题次数</p>
          <p class="this-num">{{answerCount}}</p>
        </div>
        <img v-if="!info.avatar" class="headImg" src="../../image/first/head.jpg" />
        <img v-if="info.avatar" class="headImg" :src="info.avatar" />
      </div>

      <router-link to="/scoreShop" tag="div" class="jifen">
        <img src="../../image/task/one-1.png" />
        <span>积分商城</span>
        <i class="arrow" />
      </router-link>

      <div class="menu">
        <router-link :to="`/activyRule?id=${ac.id}`" tag="div" class="item">
          <img src="../../image/task/one-2.png" />
          <span>活动规则</span>
          <i class="arrow" />
        </router-link>

        <router-link :to="`/lookRank?id=${ac.id}`" tag="div" class="item">
          <img src="../../image/task/one-3.png" />
          <span>查看排行</span>
          <i class="arrow" />
        </router-link>

        <router-link class="item" tag="div" :to="`/OneWrongList?id=${ac.id}`">
          <img src="../../image/task/one-4.png" />
          <span>查看错题</span>
          <i class="arrow" />
        </router-link>
        
        <router-link class="item" tag="div" :to="`/oneWrongDetail/${ac.id}`">
          <img src="../../image/task/one-5.png" />
          <span>前日解析</span>
          <i class="arrow" />
        </router-link>
      </div>

      <div class="wantBtn" @click="toToday">我要挑战</div>

    </div>
</template>

<script>
import { getQuery, baseUrl, upFile } from '../../common/util';
export default {
  data () {
      return {
        active: 0,
        info: {},
        totalIntegral: 0, // 用户所得积分
        answerCount: 0, // 答题次数"
        ac: {
        },
        token: ''
      }
  },
  beforeMount() {
    this.token = getQuery('token');
    if (this.token) {
      localStorage.setItem('ng-token', this.token);
    }
  },
  mounted() {
    let info = localStorage.getItem('ng-data');
    if (info) {
      this.info = JSON.parse(info);
    } else {
      this.getInfo();
    }
    this.getActivity();
  },
  methods: {
    getInfo() {
      this.$http.get(`/api/user/getInfo`, {
      })
      .then((res) => {
        localStorage.setItem('ng-data', JSON.stringify(res.data.data));
        this.info = res.data.data;
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    toToday() {
      this.$router.push(`/oneHundredToday?id=${this.ac.id}`)
    },
    getActivity() {
      this.$http.get('/api/activity/info', {

      })
      .then((res) => {
        this.ac = res.data.data;
        this.getData(this.ac.id);
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    // 获取数据
    getData(id) {
      this.$http.get(`/api/activity/${id}/myInfo`, {
      })
      .then((res) => {
        this.totalIntegral = res.data.data.totalIntegral
        this.answerCount = res.data.data.answerCount
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    }
  }

}
</script>

<style lang="less" scoped>
    .oneHundred {
        min-height: 100vh;
        background-color: #79cccb;
        padding: 0 0.5rem 0.5rem;
        box-sizing: border-box;
        .head {
          padding-top: 0.6rem;
          text-align: center;
          color: white;
          .title {
            font-size: 0.38rem;
          }
          .time {
            font-size: 0.32rem;
            margin-top: 0.1rem;
          }
        }
        .thisDay {
          display: flex;
          background-color: white;
          border-radius: 0.12rem;
          padding: 0.3rem 0;
          margin-top: 0.8rem;
          position: relative;
          .headImg {
            position: absolute;
            top: -0.4rem;
            left: 50%;
            transform: translateX(-50%);
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 1.2rem;
          }
          .this-data {
            width: 50%;
            text-align: center;
            .this-t {
              font-size: 0.26rem;
            }
            .this-num {
              font-size: 0.42rem;
              height: 0.5rem;
              width: 0.8rem;
              margin: 0.06rem auto 0;
              border-top: 1px solid #f4f6f6;
            }
          }
        }
        .jifen {
          background-color: white;
          border-radius: 0.12rem;
          height: 1rem;
          width: 100%;
          display: flex;
          align-items: center;
          margin: 0.2rem auto;
          font-size: 0.3rem;
          padding-left: 0.3rem;
          box-sizing: border-box;
          position: relative;
          img {
            width: 0.5rem;
            height: 0.5rem;
            margin-right: 0.3rem;
          }
        }
        .menu {
          background-color: white;
          border-radius: 0.12rem;
          font-size: 0.3rem;
          padding-left: 0.3rem;
          box-sizing: border-box;
          .item {
            height: 1rem;
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;
            border-bottom: 1px solid #f4f6f6;
            img {
              width: 0.5rem;
              height: 0.5rem;
              margin-right: 0.3rem;
            }
          }
        }
    }
    .arrow {
      position: absolute;
      right: 0.3rem;
      top: 50%;
      transform: translateY(-50%);
      background-image: url('../../image/task/right1.png');
      background-size: 100% 100%;
      width: 0.3rem;
      height: 0.3rem;
      display: block;
    }
    .wantBtn {
      height: 1rem;
      border-radius: 1rem;
      line-height: 1rem;
      background-color: white;
      font-size: 0.32rem;
      text-align: center;
      margin-top: 0.35rem;
    }
</style>
